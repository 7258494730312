.flexbin {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin: -2.5px;
}

.flexbin:after {
    content: '';
    flex-grow: 999999999;
    min-width: 300px;
    height: 0;
}

.flexbin>* {
    position: relative;
    display: block;
    height: 300px;
    margin: 2.5px;
    flex-grow: 1;
}

.flexbin>*>img {
    height: 300px;
    object-fit: cover;
    max-width: 100%;
    min-width: 100%;
    vertical-align: bottom;
}

.flexbin.flexbin-margin {
    margin: 2.5px;
}

@media (max-width: 980px) {
    .flexbin {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
        margin: -2.5px;
    }

    .flexbin:after {
        content: '';
        flex-grow: 999999999;
        min-width: 150px;
        height: 0;
    }

    .flexbin>* {
        position: relative;
        display: block;
        height: 150px;
        margin: 2.5px;
        flex-grow: 1;
    }

    .flexbin>*>img {
        height: 150px;
        object-fit: cover;
        max-width: 100%;
        min-width: 100%;
        vertical-align: bottom;
    }

    .flexbin.flexbin-margin {
        margin: 2.5px;
    }
}

@media (max-width: 400px) {
    #header {
        width: 100%;
        min-height: 350px;
    }

    .bg-img {
        height: 45vh;
    }

    .flexbin {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
        margin: -2.5px;
    }

    .flexbin:after {
        content: '';
        flex-grow: 999999999;
        min-width: 100px;
        height: 0;
    }

    .flexbin>* {
        position: relative;
        display: block;
        height: 100px;
        margin: 2.5px;
        flex-grow: 1;
    }

    .flexbin>*>img {
        height: 100px;;
        object-fit: cover;
        max-width: 100%;
        min-width: 100%;
        vertical-align: bottom;
    }

    .flexbin.flexbin-margin {
        margin: 2.5px;
    }
}
